import React from "react";
import '../assets/css/templates/app-template.css';
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header, { CurrentPageType } from "../components/Header";
import { ContextWithAppsItemProps } from "../types/appsItem";
import { Link } from "gatsby";
import ReactGA from 'react-ga';

const AppTemplate: React.FC<ContextWithAppsItemProps> = ( { pageContext: { id, name, iconName, iosLink, androidLink} }) => {
  const data = require(`../data/appsDataContent/${id}.html`).default;
   
    React.useEffect(() => {
  		ReactGA.initialize('UA-88504612-1');
  		ReactGA.pageview(`/apps/${id}`);
  	}, []);
  	
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Pixel Works Software: ${name}`}</title>
      </Helmet>
      <div className="gradient"></div>
      <Header currentPage={CurrentPageType.APPS} />
      <div className="gradient"></div>
      <main className="apps-template__container">
        <Link className="apps-template__back-button" to="/apps">⇦ BACK</Link>
        <h4 className="apps-template__name">{name}</h4>
        <div className="apps-template__description-container">
          <img className="apps-template__icon" src={require(`../assets/images/${iconName}`).default} alt={`${name}} Icon`} width={200} height={200}/>
          <div className="apps-template__description-content" dangerouslySetInnerHTML={{__html: data}}></div> 
        </div>
        <div className="apps-template__app-store-links">
          {iosLink && (
            <a className="apps-template__ios-link" href={iosLink} target="_blank">Download on AppStore</a>
          )}
          {androidLink && (
            <a className="apps-template__android-link" href={androidLink} target="_blank">Download on Google Play</a>
          )}
        </div>
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default AppTemplate;